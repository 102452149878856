<template>
  <v-app>
    <v-navigation-drawer app clipped v-model="drawer">
      <v-list-item class="py-3">
        <v-list-item-avatar size="50">
          <v-img src="@/assets/images/user.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <span class="caption">{{ userData ? userData.nama : "" }}</span>
          <span class="caption">{{ myRole }}</span>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list nav dense>
        <v-list-item to="/home" color="primary">
          <v-list-item-action>
            <v-icon size="24">mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-regular"
              >Home</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="userData ? userData.hak_akses == 'HA01' : ''"
          to="/ppdb"
          color="primary"
        >
          <v-list-item-action>
            <v-icon size="24">mdi-file-document</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-regular">
              PPDB Online
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/arsip-file" color="primary">
          <v-list-item-action>
            <v-icon size="24">mdi-file-document</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-regular">
              Arsip File
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="doLogout()" color="primary">
          <v-list-item-action>
            <v-icon size="24">mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-regular">
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed clipped-left app dark style="background-color: #2a9d8f">
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="drawer = true" />
      <v-avatar class="hidden-md-and-down mr-3" tile>
        <v-img src="@/assets/images/logo.png" contain />
      </v-avatar>
      <div>
        <p class="ethol-nav-brand">
          {{ brand }}
        </p>
        <p class="ethol-nav-long-brand">
          <small>{{ long_brand }}</small>
        </p>
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import roleApp from "@/static/role.js";
export default {
  computed: {
    ...mapGetters(["isLoggedIn", "userData"]),
    myRole() {
      if (this.userData) {
        const roles = roleApp.roles;
        const idRole = this.userData.hak_akses;
        var found = roles.find((e) => e.kode === idRole);
        return found.name;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      brand: "SIAKAD",
      long_brand: "Sistem Informasi Akademik MTs Asy-Syukuriyah",
      drawer: false,
      fixed: false,
    };
  },
  watch: {
    isLoggedIn(v) {
      if (v == true) {
        this.drawer = true;
      }
    },
  },
  methods: {
    ...mapMutations(["setUser", "setToken"]),
    doLogout() {
      this.setUser(null);
      this.setToken(null);
      this.$cookie.set("token", null, 1);
      this.$router.go("/");
    },
  },
};
</script>

<style>
.ethol-nav-brand {
  margin-bottom: 0px !important;
  margin-top: 6px;
  font-weight: bold;
  font-size: 14pt;
}
.ethol-nav-long-brand {
  margin-bottom: 0px !important;
  margin-top: -8px;
  font-size: 14pt;
}
/* hide the "scrim", it's pointless */
.v-overlay--active .v-overlay__scrim {
  display: none;
}
/* style the overlay container as required */
.v-overlay--active {
  backdrop-filter: blur(3px);
  background: rgb(0 0 0 / 0.6);
}
/* if you have an auto dark theme
   for prefers-color-scheme: dark
   I find the 0.8 too dark
*/
@media (prefers-color-scheme: dark) {
  .v-overlay--active {
    background: rgb(0 0 0 / 0.4);
  }
}
@media all and (max-width: 480px) {
  .ethol-nav-long-brand {
    margin-bottom: 0px !important;
    margin-top: -8px;
    font-size: 12pt;
  }
}
</style>
