import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// sweet alert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

// Axios
import axios from "axios";
const base = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});
Vue.prototype.$http = base;

//Cookies
var VueCookie = require("vue-cookie");
Vue.use(VueCookie);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
