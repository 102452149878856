<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        persistent
        max-width="800"
        v-model="dialog"
      >
        <v-card>
          <v-toolbar dense color="#e3e3e3">Form Upload File</v-toolbar>
          <v-card-text>
            <v-row style="margin-top: 20px; margin-bottom: 10px">
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Deskripsi File"
                  placeholder="Tuliskan deskripsi singkat file ..."
                  hide-details
                  prepend-icon=""
                  prepend-inner-icon="mdi-format-color-text"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="fileArsip"
                  label="File"
                  hide-details
                  prepend-icon=""
                  prepend-inner-icon="mdi-attachment mdi-rotate-90"
                  filled
                  accept=".pdf, .png, .jpeg, .jpg"
                  @change="fileToBase64(fileArsip)"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="tahun"
                  filled
                  label="Tahun"
                  hide-details
                  prepend-inner-icon="mdi-calendar-today"
                  v-model="tahun_selected"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              color="primary"
              @click="saveArsip"
              depressed
              class="text-none"
            >
              <v-icon small>mdi-check-circle-outline</v-icon>&nbsp;Simpan</v-btn
            >
            <v-btn text @click="dialog = false" class="text-none">Tutup</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      name: "",
      file_base_64: "",
      extensi_file: "",
      original_name: "",
      fileArsip: null,
      tahun_selected: new Date().getFullYear(),
    };
  },
  computed: {
    tahun() {
      const nowYear = new Date().getFullYear();
      const data = [];
      for (let mundur = 0; mundur < 10; mundur++) {
        data.push(nowYear - mundur);
      }
      return data;
    },
  },
  methods: {
    async fileToBase64(file) {
      if (file) {
        const base64 = await this.toBase64(file);
        const ext = file.name.split(".");
        this.file_base_64 = base64;
        this.extensi_file = ext[ext.length - 1];
        this.original_name = ext[0];
      } else {
        this.file_base_64 = "";
        this.extensi_file = "";
        this.original_name = "";
        this.fileArsip = null;
      }
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    saveArsip() {
      let cleanError = true;
      let messageError = "";
      if (this.name == "" || this.name.trim().length === 0) {
        cleanError = false;
        messageError = "Deskripsi singkat file wajib diisi !";
      } else if (this.original_name == "") {
        messageError = "Harap pilih file !";
        cleanError = false;
      }

      if (cleanError == true) {
        let token = this.$cookie.get("token");
        this.$http
          .post(
            "/arsip",
            {
              extensi_file: this.extensi_file,
              file_base_64: this.file_base_64,
              original_name: this.original_name,
              file_name: this.name,
              file_year: this.tahun_selected,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.data.success == true) {
              this.$swal({
                icon: "success",
                title: "Berhasil...",
                text: "File berhasil disimpan !",
                customClass: "swal-aplikasi",
              });

              this.dialog = false;
              this.file_base_64 = "";
              this.extensi_file = "";
              this.original_name = "";
              this.fileArsip = null;

              this.$emit("savesukses");
            } else {
              this.$swal({
                icon: "error",
                title: "Maaf",
                text: res.data.message,
                customClass: "swal-aplikasi",
              });
            }
          });
      } else {
        this.$swal({
          icon: "info",
          title: "Oops...",
          text: messageError,
          customClass: "swal-aplikasi",
        });
      }
    },
  },
};
</script>
