import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Ppdb from "../views/Ppdb.vue";
import detailPpdb from "../views/detailPpdb.vue";
import ArsipFile from "../views/arsipFile.vue";

import store from "../store/index.js";
const cookie = require("vue-cookie");
const axios = require("axios");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      auth: true,
    },
  },
  {
    path: "/arsip-file",
    name: "ArsipFile",
    component: ArsipFile,
    meta: {
      auth: true,
    },
  },
  {
    path: "/ppdb",
    name: "PPDB",
    component: Ppdb,
    meta: {
      auth: true,
    },
  },
  {
    path: "/ppdb/:id",
    name: "Detail PPDB",
    component: detailPpdb,
    meta: {
      auth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // mengecek ada tidak meta auth di dalam meta
  if (to.matched.some((record) => record.meta.auth)) {
    // cek di localstorage auth, jika false maka diarahkan ke halaman login
    const token = cookie.get("token");
    if (token) {
      let urlNya = process.env.VUE_APP_API_URL + "/user-profile";
      await axios
        .get(urlNya, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(function (response) {
          // console.log(response);
          store.dispatch("updateUser", response.data);
          store.dispatch("updateToken", token);
          next();
        })
        .catch(function (error) {
          console.log(error);
          router.push("/");
        });
    } else {
      router.push("/");
    }
  } else {
    next();
  }
});
export default router;
