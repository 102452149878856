<template>
  <div class="login">
    <v-row style="height: 100%">
      <v-col
        style="height: 100%"
        cols="12"
        md="4"
        offset-md="4"
        sm="12"
        lg="4"
        offset-lg="4"
      >
        <v-card class="card-login" outlined>
          <v-card-text>
            <br />
            <center>
              <h1>Login</h1>
            </center>
            <br />
            <div
              style="
                background-color: #efefef;
                padding: 10px;
                border-radius: 5px;
              "
            >
              Silakan mengisi form login (email dan password) untuk masuk
              kedalam sistem SIAKAD MTs Asy-Syukuriyah Payaman
            </div>
            <div style="margin-top: 20px">
              <v-text-field
                label="Email"
                placeholder="eg. muhammad@gmail.com"
                outlined
                prepend-inner-icon="mdi-account-box"
                v-model="email"
                :rules="emailRules"
                @keypress.enter="login"
              ></v-text-field>
              <v-text-field
                label="Password"
                placeholder="*******"
                prepend-inner-icon="mdi-lock"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-model="password"
                outlined
                :rules="passRules"
                @keypress.enter="login"
              ></v-text-field>
              <v-btn
                color="primary"
                block
                :disabled="loading"
                :loading="loading"
                @click="login"
              >
                Login <v-icon right>mdi-key</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      showPassword: false,
      loading: false,
      email: "",
      password: "",
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail tidak valid",
      ],
      passRules: [
        (v) => !!v || "Password wajid diisi",
        (v) => (v && v.length >= 6) || "Minimal Password 6 Karakter",
      ],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  mounted() {
    let token = this.$cookie.get("token");
    if (token) {
      this.$http
        .get("/user-profile", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => this.$router.push("/home"));
    }
  },
  methods: {
    ...mapMutations(["setUser", "setToken"]),
    async login() {
      let self = this;
      this.loading = true;
      await this.$http
        .post("/login", {
          email: this.email,
          password: this.password,
        })
        .finally(() => (this.loading = false))
        .then((res) => {
          this.setUser(res.data.user);
          this.setToken(res.data.access_token);
          this.$cookie.set("token", res.data.access_token, 1);
          this.$router.push("/home");
        })
        .catch((e) => {
          console.log(e);
          console.log("e.response.status", e.response.status);
          if (e.response.status == 401) {
            self.$swal({
              icon: "error",
              title: "Oops...",
              text: "Akun tidak ditemukan !",
              customClass: "swal-aplikasi",
            });
          } else {
            self.$swal({
              icon: "error",
              title: "Oops...",
              text: "Login gagal, kesalahan sistem !",
              customClass: "swal-aplikasi",
            });
          }
        });
    },
  },
};
</script>

<style>
.swal-aplikasi {
  font-family: sans-serif !important;
}
.login {
  height: 100% !important;
  margin-top: 50px;
}
.card-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  border-radius: 15px !important;
  box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
}
</style>
