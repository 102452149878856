var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arsipFile"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Data Arsip "),_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","depressed":""},on:{"click":_vm.openDialogUpload}},[_c('v-icon',[_vm._v("mdi-cloud-upload")]),_vm._v("   "),_c('span',[_vm._v("Upload File")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Pencarian","placeholder":"Cari ...","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataArsip,"search":_vm.search},scopedSlots:_vm._u([{key:"item.urutan",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.opsi",fn:function(ref){
var item = ref.item;
return [_c('center',[_c('v-btn',{attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.viewFile(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)],1)]}},{key:"item.waktuuploadnya",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.waktuIndonesia(item.created_at))+" ")]}},{key:"item.aksesnya",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roleRelation(item.role_id))+" ")]}}],null,true)})],1)],1),_c('DialogUploadFile',{ref:"ref_dialoguploadfile",on:{"savesukses":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }