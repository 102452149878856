<template>
  <div class="detail-ppdb">
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Detail Pendaftaran Siswa</v-toolbar-title>
      </v-toolbar>
      <v-tabs horizontal>
        <v-tab>
          <v-icon left> mdi-account </v-icon>
          Identitas Siswa
        </v-tab>
        <v-tab>
          <v-icon left> mdi-history </v-icon>
          Riwayat Sekolah
        </v-tab>
        <v-tab>
          <v-icon left> mdi-account-multiple </v-icon>
          Data Ortu
        </v-tab>
        <v-tab>
          <v-icon left> mdi-account-check </v-icon>
          Data Wali
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <table class="tabel-standard" v-if="dataPpdb">
                <tr>
                  <th width="15%">NISN</th>
                  <td width="1%">:</td>
                  <td>
                    {{ dataPpdb.nisn ? dataPpdb.nisn : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Nama</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.nama_siswa ? dataPpdb.nama_siswa : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Jenis Kelamin</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.jenis_kelamin ? dataPpdb.jenis_kelamin : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Agama</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.agama ? dataPpdb.agama : "" }}
                  </td>
                </tr>
                <tr>
                  <th>TTL</th>
                  <td>:</td>
                  <td>
                    {{
                      dataPpdb.tempat_lahir
                        ? dataPpdb.tempat_lahir +
                          ", " +
                          tanggalIndonesia(dataPpdb.tanggal_lahir)
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Anak Ke</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.anak_ke ? dataPpdb.anak_ke : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Saudara</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.jumlah_saudara ? dataPpdb.jumlah_saudara : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Status Dalam Keluarga</th>
                  <td>:</td>
                  <td>
                    <div v-if="dataPpdb.status_dalam_keluarga">
                      <span v-if="dataPpdb.status_dalam_keluarga == 'AK'">
                        Anak Kandung
                      </span>
                      <span v-else-if="dataPpdb.status_dalam_keluarga == 'AT'">
                        Anak Tiri
                      </span>
                      <span v-else-if="dataPpdb.status_dalam_keluarga == 'AA'">
                        Anak Angkat
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Alamat</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.alamat ? dataPpdb.alamat : "" }}
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <table class="tabel-standard" v-if="dataPpdb">
                <tr>
                  <th width="15%">Asal Sekolah</th>
                  <td width="1%">:</td>
                  <td>
                    {{ dataPpdb.asal_sekolah ? dataPpdb.asal_sekolah : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Alamat Sekolah</th>
                  <td>:</td>
                  <td>
                    {{
                      dataPpdb.alamat_asal_sekolah
                        ? dataPpdb.alamat_asal_sekolah
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Prestasi</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.prestasi ? dataPpdb.prestasi : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Bantuan / Beasiswa</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.beasiswa ? dataPpdb.beasiswa : "" }}
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <table class="tabel-standard" v-if="dataPpdb">
                <tr>
                  <th width="15%">Nama Ayah</th>
                  <td width="1%">:</td>
                  <td>
                    {{ dataPpdb.nama_ayah ? dataPpdb.nama_ayah : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Agama Ayah</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.agama_ayah ? dataPpdb.agama_ayah : "" }}
                  </td>
                </tr>
                <tr>
                  <th>HP Ayah</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.hp_ayah ? dataPpdb.hp_ayah : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Tempat Lahir Ayah</th>
                  <td>:</td>
                  <td>
                    {{
                      dataPpdb.tempat_lahir_ayah
                        ? dataPpdb.tempat_lahir_ayah
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Pekerjaan Ayah</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.pekerjaan_ayah ? dataPpdb.pekerjaan_ayah : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Alamat Ayah</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.alamat_ayah ? dataPpdb.alamat_ayah : "" }}
                  </td>
                </tr>
                <tr>
                  <th width="15%">Nama Ibu</th>
                  <td width="1%">:</td>
                  <td>
                    {{ dataPpdb.nama_ibu ? dataPpdb.nama_ibu : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Agama Ibu</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.agama_ibu ? dataPpdb.agama_ibu : "" }}
                  </td>
                </tr>
                <tr>
                  <th>HP Ibu</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.hp_ibu ? dataPpdb.hp_ibu : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Tempat Lahir Ibu</th>
                  <td>:</td>
                  <td>
                    {{
                      dataPpdb.tempat_lahir_ibu ? dataPpdb.tempat_lahir_ibu : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Pekerjaan Ibu</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.pekerjaan_ibu ? dataPpdb.pekerjaan_ibu : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Alamat Ibu</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.alamat_ibu ? dataPpdb.alamat_ibu : "" }}
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <table class="tabel-standard" v-if="dataPpdb">
                <tr>
                  <th width="15%">Nama Wali</th>
                  <td width="1%">:</td>
                  <td>
                    {{ dataPpdb.nama_wali ? dataPpdb.nama_wali : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Agama Wali</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.agama_wali ? dataPpdb.agama_wali : "" }}
                  </td>
                </tr>
                <tr>
                  <th>HP Wali</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.hp_wali ? dataPpdb.hp_wali : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Tempat Lahir Wali</th>
                  <td>:</td>
                  <td>
                    {{
                      dataPpdb.tempat_lahir_wali
                        ? dataPpdb.tempat_lahir_wali
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Pekerjaan Wali</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.pekerjaan_wali ? dataPpdb.pekerjaan_wali : "" }}
                  </td>
                </tr>
                <tr>
                  <th>Alamat Wali</th>
                  <td>:</td>
                  <td>
                    {{ dataPpdb.alamat_wali ? dataPpdb.alamat_wali : "" }}
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const moment = require("moment");
moment.locale("id");
export default {
  name: "Home",
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  data() {
    return {
      dataPpdb: null,
    };
  },
  components: {},
  created() {
    this.getDetailData();
  },
  methods: {
    tanggalIndonesia(v) {
      return moment(v, "YYYY-MM-DD").format("DD MMMM YYYY");
    },
    getDetailData() {
      let self = this;
      let token = this.$cookie.get("token");
      this.$http
        .post(
          "/ppdb/get-by-id",
          {
            id: this.$route.params.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(function (response) {
          self.dataPpdb = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.tabel-standard {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.tabel-standard th,
.tabel-standard td {
  text-align: left;
  padding: 8px;
}

.tabel-standard tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
