<template>
  <div class="home">Home</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  components: {},
};
</script>
