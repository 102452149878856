export default {
  roles: [
    {
      kode: "HA01",
      name: "Admin",
    },
    {
      kode: "HA02",
      name: "Waka Kurikulum",
    },
    {
      kode: "HA03",
      name: "Waka Kesiswaaan",
    },
    {
      kode: "HA04",
      name: "Waka Sarpras",
    },
    {
      kode: "HA05",
      name: "Humas",
    },
    {
      kode: "HA06",
      name: "Guru",
    },
  ],
};
