<template>
  <div class="ppdb">
    <v-card outlined>
      <v-card-title>
        Data PPDB Online
        <v-spacer></v-spacer>
        <v-btn color="green" small dark @click="downloadExcel">
          <v-icon left>mdi-file-excel-box</v-icon>
          Download Excel
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Pencarian"
          placeholder="Cari ..."
          outlined
        >
        </v-text-field>
        <v-data-table :headers="headers" :items="dataPpdb" :search="search">
          <template #[`item.ttl`]="{ item }">
            {{
              item.tempat_lahir + ", " + tanggalIndonesia(item.tanggal_lahir)
            }}
          </template>
          <template #[`item.opsi`]="{ item }">
            <center>
              <v-btn color="success" small @click="printppdb(item)">
                <v-icon small>mdi-printer</v-icon>
              </v-btn>
              &nbsp;
              <v-btn color="primary" small @click="detail(item)">
                <v-icon small>mdi-magnify</v-icon>
              </v-btn>
              &nbsp;
              <v-btn color="warning" small @click="edit(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              &nbsp;
              <v-btn color="error" small @click="hapus(item)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </center>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogEditPpdb ref="ref_dialog_edit_ppdb" @berhasilupdate="getData" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const moment = require("moment");
moment.locale("id");
import XLSX from "xlsx";
import DialogEditPpdb from "@/components/DialogEditPpdb.vue";
export default {
  name: "Ppdb",
  components: {
    DialogEditPpdb,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "No Pendaftaran",
          align: "start",
          value: "urutan_daftar",
        },
        {
          text: "NISN",
          align: "start",
          value: "nisn",
        },
        { text: "Nama", value: "nama_siswa" },
        { text: "JK", value: "jenis_kelamin" },
        { text: "TTL", value: "ttl" },
        { text: "Asal Sekolah", value: "asal_sekolah" },
        { text: "Opsi", value: "opsi", align: "center" },
      ],
      dataPpdb: [],
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      let token = this.$cookie.get("token");
      this.$http
        .get("/ppdb/get-basic", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(function (response) {
          self.dataPpdb = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    tanggalIndonesia(v) {
      return moment(v, "YYYY-MM-DD").format("DD MMMM YYYY");
    },
    detail(item) {
      this.$router.push("/ppdb/" + item.id);
    },
    edit(item) {
      this.$refs.ref_dialog_edit_ppdb.assignData(item);
    },
    hapus(item) {
      let token = this.$cookie.get("token");
      this.$swal({
        icon: "question",
        title: "Apakah anda yakin menghapus data " + item.nama_siswa,
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: "swal-aplikasi",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$http
            .delete("/ppdb/" + item.id, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then(function (response) {
              return response;
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          this.getData();
          this.$swal.fire({
            icon: result.data.success ? "success" : "error",
            title: result.data.message,
            customClass: "swal-aplikasi",
          });
        }
      });
    },
    downloadExcel() {
      const daftarExport = [
        [
          "NO",
          "NO Daftar",
          "NISN",
          "Nama",
          "Jenis Kelamin",
          "Tempat Lahir",
          "Tanggal Lahir",
          "Agama",
          "Alamat",
          "Anak Ke",
          "Jumlah Saudara",
          "Status Dalam keluarga",

          "Asal Sekolah",
          "Alamat Asal Sekolah",
          "Prestasi",
          "Beasiswa",

          "Nama Ayah",
          "Agama Ayah",
          "HP Ayah",
          "Tempat Lahir Ayah",
          "Pekerjaan Ayah",
          "Alamat Ayah",
          "Nama Ibu",
          "Agama Ibu",
          "Hp Ibu",
          "Tempat Lahir Ibu",
          "Pekerjaan Ibu",
          "Alamat Ibu",
          "Nama Wali",
          "Agama Wali",
          "Hp Wali",
          "Tempat lahir Wali",
          "Pekerjaan Wali",
          "Alamat Wali",

          "Tanggal Daftar",
        ],
      ];

      for (let index = 0; index < this.dataPpdb.length; index++) {
        const dp = this.dataPpdb[index];
        let statusDalamKeluarga = "";
        if (dp.status_dalam_keluarga == "AK") {
          statusDalamKeluarga = "Anak Kandung";
        } else if (dp.status_dalam_keluarga == "AT") {
          statusDalamKeluarga = "Anak Tiri";
        } else if (dp.status_dalam_keluarga == "AA") {
          statusDalamKeluarga = "Anak Angkat";
        }
        daftarExport.push([
          index + 1,
          dp.urutan_daftar,
          dp.nisn,
          dp.nama_siswa,
          dp.jenis_kelamin,
          dp.tempat_lahir,
          dp.tanggal_lahir,
          dp.agama,
          dp.alamat,
          dp.anak_ke,
          dp.jumlah_saudara,
          statusDalamKeluarga,

          dp.asal_sekolah,
          dp.alamat_asal_sekolah,
          dp.prestasi,
          dp.beasiswa,

          dp.nama_ayah,
          dp.agama_ayah,
          dp.hp_ayah,
          dp.tempat_lahir_ayah,
          dp.pekerjaan_ayah,
          dp.alamat_ayah,
          dp.nama_ibu,
          dp.agama_ibu,
          dp.hp_ibu,
          dp.tempat_lahir_ibu,
          dp.pekerjaan_ibu,
          dp.alamat_ibu,
          dp.nama_wali,
          dp.agama_wali,
          dp.hp_wali,
          dp.tempat_lahir_wali,
          dp.pekerjaan_wali,
          dp.alamat_wali,

          moment(dp.created_at, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY"),
        ]);
      }
      const ws = XLSX.utils.aoa_to_sheet(daftarExport);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "");
      XLSX.writeFile(wb, "Data-PPDB.xlsx");
    },
    printppdb(item) {
      window.open(process.env.VUE_APP_CETAK_URL + "?id=" + item.id);
    },
  },
};
</script>
