<template>
  <div class="arsipFile">
    <v-card outlined>
      <v-card-title>
        Data Arsip
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="openDialogUpload"
          class="text-none"
          depressed
        >
          <v-icon>mdi-cloud-upload</v-icon> &nbsp; <span>Upload File</span>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Pencarian"
          placeholder="Cari ..."
          outlined
        >
        </v-text-field>
        <v-data-table :headers="headers" :items="dataArsip" :search="search">
          <template #[`item.urutan`]="{ index }">
            {{ index + 1 }}
          </template>
          <template #[`item.opsi`]="{ item }">
            <center>
              <v-btn color="primary" depressed small @click="viewFile(item)">
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </center>
          </template>
          <template #[`item.waktuuploadnya`]="{ item }">
            {{ waktuIndonesia(item.created_at) }}
          </template>
          <template #[`item.aksesnya`]="{ item }">
            {{ roleRelation(item.role_id) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogUploadFile ref="ref_dialoguploadfile" @savesukses="getData" />
  </div>
</template>

<script>
import DialogUploadFile from "@/components/DialogUploadFile.vue";
import { mapGetters } from "vuex";
import roleApp from "@/static/role.js";
const moment = require("moment");
moment.locale("id");
export default {
  name: "Home",
  components: {
    DialogUploadFile,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "No",
          align: "start",
          value: "urutan",
          width: "5%",
        },
        {
          text: "Keterangan",
          align: "start",
          value: "file_name",
          width: "40%",
        },
        { text: "Tahun", value: "file_year", width: "10%" },
        { text: "User", value: "user_name", width: "15%" },
        { text: "Akses", value: "aksesnya", width: "10%" },
        { text: "Waktu Upload", value: "waktuuploadnya", width: "15%" },
        { text: "Opsi", value: "opsi", align: "center", width: "5%" },
      ],
      dataArsip: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  created() {
    this.getData();
  },
  methods: {
    roleRelation(idRole) {
      const roles = roleApp.roles;
      var found = roles.find((e) => e.kode === idRole);
      return found.name;
    },
    waktuIndonesia(v) {
      return moment(v, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY - HH:mm");
    },
    openDialogUpload() {
      this.$refs.ref_dialoguploadfile.dialog = true;
    },
    getData() {
      let self = this;
      let token = this.$cookie.get("token");
      this.$http
        .get("/arsip", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(function (response) {
          self.dataArsip = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    viewFile(item) {
      let urlFile = process.env.VUE_APP_API_URL + "/" + item.file_path;
      window.open(urlFile, "_blank").focus();
    },
  },
};
</script>
